import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import ContactPage from './contact-me'
export default function Contact() {
  return (
    <>
    <ContactPage/>
    
    </>
  );
}
